<template>   

  <!-- Modal edit -->
  <div class="modal fade" id="edit-Modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-medium">
          <div class="modal-content modal-custom">
              <div class="modal-body body-detail">
                  <a type="button" class="close fa-pull-right" data-bs-dismiss="modal" aria-label="Close" style="opacity: 1;">
                      <span class="close-popup"></span>
                  </a>
                  <div class="box-wrapper-modal">
                      <h2>Rename the search</h2>
                      <div class="box-input mb-3"><input id="keyword-name"  type="text" class="form-control" placeholder="test"  v-model="input_name"></div>
                      <div><button  @click="update_keyword()" class="btn-orange" style="width: 200px;">{{$t('Enregistrer')}}</button></div>
                  </div>
              </div>
          </div>
      </div>
  </div> 
</template> 

<script>
import { Modal } from 'bootstrap'
export default {
  name: "ModalEditKeyword",
  props: {
    id: {
      type: Number, 
    },
    name: {
      type: String,
    } 
  }, 
  data() {
    return {
      input_name : "", 
      modal : null
    }
  },
  
  methods: { 
    update_keyword(){ 
        this.$store.dispatch("users/update_keyword", {keyword_id: this.id, keyword_name:this.input_name}).then(()=> {
            console.log(this.id,this.input_name )
            this.modal.hide();
        });
    }
  },  
    watch: {
      name(){
        this.input_name = this.name
      }, 
    },
    mounted() {
        this.modal = new Modal(document.getElementById('edit-Modal'));
    },
};
</script>

<style>
.w360 {
    width: 360px;
}
.modal .custom-modal-header {
  padding: 16px;
}
.modal .edit-keyword input {
  position: relative;
  width: 268px;
  height: 31px;
  background: #f7f5f5;
  border: none;
  border-radius: 20px;
  padding: 0 10px;
  outline: none;
  margin-bottom: 20px;
  margin-top: 14px;
}
.btn-orange {
  position: relative;
  border-radius: 15px;
  font-size: 11px;
  border: none;
  padding: 6px 42px;
  color: #fff;
  display: inline-block;
  background: linear-gradient(
    to bottom,
    rgba(255, 117, 63, 1) 0%,
    rgba(232, 69, 3, 1) 100%
  );
}
</style>