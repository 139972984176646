

<template>
 <!-- Modal confirm delete --> 
   <div class="modal fade" id="delete-Modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-medium">
            <div class="modal-content modal-custom">
                <div class="modal-body body-detail" style="padding: 20px 10px 10px 10px;">
                    <div class="box-wrapper-modal">
                        <h2 style="color: #232426 ;font-size: 16px;">Delete this search?</h2>
                        <div>
                            <button class="btn-orange btn-border-orange me-4" data-bs-dismiss="modal" style="width: 100px;" @click="modal.hide()">{{$t('Non')}}</button>
                            <button class="btn-orange" style="width: 100px;"  @click="removeKeyword()">{{$t('Oui')}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>

<script>
import { Modal } from 'bootstrap'
export default {
  name: "modalRemoveKeyword",

  props: {
    id: {
      type: Number
    },
  }, 
  data() {
    return {
      show : false,
      modal : null
    }
  }, 
  methods: {   
    removeKeyword() {
        this.$store.dispatch("users/removeKeyword", this.id).then(()=> {
          console.log('deleted id '+this.id);
          this.modal.hide();
        });
    },
  },
  mounted() {     
      this.modal = new Modal(document.getElementById('delete-Modal'));     
  },
};
</script>

<style>
.w360 {
    width: 360px;
}
</style>