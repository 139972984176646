<template>
  <div>
    <Header />
    <div class="container-fluid">
      <div class="wrapper-box wrapper-box-keyword" style="min-height: 583px">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12"><h1>{{$t('headers_menu.MySearches')}}</h1></div>
          <div
            v-for="user_keyword in user_keywords"
            :key="user_keyword.id"
            class="col-lg-12 col-md-12 col-12"
          >
            <div class="box-white-rec">
              <span class="date">{{
                DDMMMYY(user_keyword.created_at)
              }}</span>

              <a :href="searchByHistoryKeyword(user_keyword.link, 'keyword_id', user_keyword.id)" target="_blank" class="detail-research">
                {{user_keyword.name}}
              </a>
             
              <div class="icon-for-research">
                <a @click="showRemoveKeyword(user_keyword.id)"   data-bs-toggle="modal"  data-bs-target="#delete-Modal" class="icon mr-2" ><img src="@/assets/images/icon-delete.svg" alt=""/></a>                
                <a @click="showEditKeyword(user_keyword.id, user_keyword.name)"  data-bs-toggle="modal"  data-bs-target="#edit-Modal" class="icon mr-2" ><img src="@/assets/images/icon-edit.svg" alt=""/></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <Loader :isLoad="isLoad" />

    <ModalEdit :id="tempId" :name="tempName" />    
    <ModalDelete :id="tempId" /> 
  
</div>
</template>


<script>
// import { Modal } from 'bootstrap'
import Header from "@/components/Layout/Header.vue";
import Footer from "@/components/Layout/Footer.vue";
import ModalEdit from "@/components/userkeyword/modal_edit_keyword.vue";
import ModalDelete from "@/components/userkeyword/modal_remove_keyword.vue";
import { DDMMMYY } from '@/utils';
import Loader from "@/components/Layout/Loader";

export default {
  name: "userkeyword",
  components: {
    Header,
    Footer,
    ModalEdit,
    ModalDelete,
    Loader
  },
  data() {
    return {
      tempName: "",
      tempId: 0, 
    };
  },
  computed: {
    user_keywords() {
      return this.$store.getters["users/data_user_keywords"];
    },
    isLoad() {
      return this.$store.getters["users/load"];
    },
  },
  mounted() {  
    this.$store.dispatch("users/userKeyword");
  },
  methods: {    
    DDMMMYY, 
    showEditKeyword(id, name) {       
      this.tempName = name;
      this.tempId = id; 
    },   
    showRemoveKeyword(id) {      
      this.tempId = id;      
    },
    searchByHistoryKeyword(link, keyParam, valueParam) {      
      const url = new URL(window.location.href.replace(this.$route.href, link));
      url.searchParams.append(keyParam, valueParam);
      return  url
    }
  }
};
</script>

<style scoped>
.btn-orange {
  border: none;
}
.title-form a {
  color: #7d8693;
  font-size: 11px;
}
.form-signin {
  max-width: 375px;
}
</style>